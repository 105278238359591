<app-subheading prompt="Applications" matIcon="category"></app-subheading>
<div class="page-container">
  <button
    mat-raised-button
    routerLink="/application/create"
    style="float: right; margin-bottom: 10px"
  >
    <i class="material-icons-outlined">add</i>&nbsp;
    <span>Create a New Application</span>
  </button>
  <br />

  <table
    mat-table
    [dataSource]="applications$ | async"
    class="mat-elevation-z8"
  >
    <ng-container matColumnDef="name" sticky>
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let application">
        <a routerLink="/application/{{ application.id }}">{{
          application.name
        }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let application">
        {{ application.description | truncate: 100 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell class="deleteRow" *matCellDef="let application">
        <button
          mat-button
          [routerLink]="['/application/', application.id, 'delete']"
          matTooltip="Delete Application"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
