<app-subheading
  [prompt]="
    (application$ | async)?.name +
    ': Trigger - ' +
    (crudAction == Crud.Create
      ? 'Create'
      : crudAction == Crud.Update
      ? 'Update'
      : 'Delete')
  "
  [iconActions]="[
    {
      icon: 'arrow_back',
      toolTip: 'Return to Application',
      routerLink: '/application/' + aid
    }
  ]"
  matIcon="notifications"
></app-subheading>

<div class="page-container">
  <form class="standard-form" [formGroup]="triggerForm">
    <fieldset [disabled]="crudAction == Crud.Delete">
      <mat-form-field class="full-width">
        <input
          matInput
          formControlName="name"
          (change)="onFieldUpdate('name')"
          placeholder="Name"
          required
        />
        <mat-error *ngIf="triggerForm.controls['name'].hasError('required')">
          Name is required.
        </mat-error>
        <mat-error *ngIf="triggerForm.controls['name'].hasError('minlength')">
          Name must be at least 3 characters long.
        </mat-error>
        <mat-error *ngIf="triggerForm.controls['name'].hasError('maxlength')">
          Name must be no more than 35 characters long.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <textarea
          matInput
          formControlName="description"
          (change)="onFieldUpdate('description')"
          matAutosizeMinRows="3"
          matAutosizeMaxRows="10"
          matTextareaAutosize
          placeholder="Description"
          required
        ></textarea>
        <mat-error
          *ngIf="triggerForm.controls['description'].hasError('required')"
        >
          Description is required.
        </mat-error>
        <mat-error
          *ngIf="triggerForm.controls['description'].hasError('minlength')"
        >
          Description must be at least 10 characters long.
        </mat-error>
        <mat-error
          *ngIf="triggerForm.controls['description'].hasError('maxlength')"
        >
          Description must be no more than 500 characters long.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <textarea
          matInput
          formControlName="message"
          (change)="onFieldUpdate('message')"
          matAutosizeMinRows="3"
          matAutosizeMaxRows="10"
          matTextareaAutosize
          placeholder="Message"
          required
        ></textarea>
        <mat-error *ngIf="triggerForm.controls['message'].hasError('required')">
          Message is required.
        </mat-error>
        <mat-error
          *ngIf="triggerForm.controls['message'].hasError('minlength')"
        >
          Message must be at least 10 characters long.
        </mat-error>
        <mat-error
          *ngIf="triggerForm.controls['message'].hasError('maxlength')"
        >
          Message must be no more than 500 characters long.
        </mat-error>
      </mat-form-field>

      <div class="grid50percent">
        <div>
          <mat-form-field class="full-width">
            <mat-label>Trigger Action *</mat-label>
            <mat-select
              formControlName="triggerAction"
              (selectionChange)="onFieldUpdate('triggerAction')"
            >
              <mat-option
                *ngFor="let triggerActionInfoItem of TriggerActionInfo"
                [value]="triggerActionInfoItem.value"
              >
                {{ triggerActionInfoItem.name }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="triggerForm.controls['triggerAction'].hasError('required')"
            >
              Action is required.
            </mat-error>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="full-width">
            <mat-label>Sensor *</mat-label>
            <mat-select
              formControlName="sensorRef"
              (selectionChange)="onFieldUpdate('sensorRef')"
              [compareWith]="objectComparisonFunction"
            >
              <mat-option
                *ngFor="let sensor of sensors"
                [value]="getSensorRef(sensor.id)"
              >
                {{ sensor.name }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="triggerForm.controls['sensorRef'].hasError('required')"
            >
              Sensor is required.
            </mat-error>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="full-width">
            <mat-label>Minimum value for trigger</mat-label>
            <input
              matInput
              formControlName="triggerRangeMin"
              (change)="onFieldUpdate('triggerRangeMin')"
              placeholder="0.0"
              required
            />
            <mat-error
              *ngIf="
                triggerForm.controls['triggerRangeMin'].hasError('required')
              "
            >
              Minimum value is required.
            </mat-error>
            <mat-error
              *ngIf="
                triggerForm.controls['triggerRangeMin'].hasError('pattern')
              "
            >
              Minimum value must be numeric.
            </mat-error>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="full-width">
            <mat-label>Maximum value for trigger</mat-label>
            <input
              matInput
              formControlName="triggerRangeMax"
              (change)="onFieldUpdate('triggerRangeMax')"
              placeholder="0.0"
              required
            />
            <mat-error
              *ngIf="
                triggerForm.controls['triggerRangeMax'].hasError('required')
              "
            >
              Maximum value is required.
            </mat-error>
            <mat-error
              *ngIf="
                triggerForm.controls['triggerRangeMax'].hasError('pattern')
              "
            >
              Maximum value must be numeric.
            </mat-error>
          </mat-form-field>
        </div>

        <div>
          <mat-checkbox
            formControlName="active"
            (click)="onFieldUpdate('active', 'Toggle')"
            labelPosition="before"
            >Trigger Is Active?</mat-checkbox
          >
        </div>
      </div>
    </fieldset>

    <br />
    <button
      mat-raised-button
      color="primary"
      *ngIf="crudAction == Crud.Create"
      [disabled]="!triggerForm.valid"
      (click)="onCreate()"
      id="btnCreate"
    >
      <mat-icon>add</mat-icon>
      Create
    </button>
    <button
      mat-raised-button
      color="primary"
      *ngIf="crudAction == Crud.Delete"
      (click)="onDelete()"
      id="btnDelete"
    >
      <mat-icon>clear</mat-icon>
      Delete
    </button>
  </form>
</div>
