<div class="card-container">
  <mat-card class="about-card">
    <mat-card-header class="card-header">
      <mat-card-title>About</mat-card-title>
      <mat-card-subtitle
        >A LoraWan based IOT management and reporting system</mat-card-subtitle
      >
    </mat-card-header>
    <img
      class="appLogoImg"
      src="../../assets/icons/android-icon-192x192-dunplab-manifest-9820.png"
      alt="Example application image"
      class="appLogoImg"
    />
    <mat-card-content>
      <p>
        This application uses a mix of technologies to deliver a distributed IOT
        management and reporting system..
      </p>
      <p><b>Version: </b> 11.01</p>
      <p><b>Designer: </b> David Somerville</p>
      <p>
        <b>Contact: </b>
        <a href="mailto:david@lupincorp.com">david@lupincorp.com</a>
      </p>
      <p>
        <b>By Lupincorp: </b>
        <a href="https://lupincorp.com" target="_blank"
          >https://lupincorp.com<img
            class="lupinCorpLogo"
            src="../../assets/images/lupincorp.png"
            style="height: 24px; margin-left: 24px"
          />
        </a>
      </p>
    </mat-card-content>
  </mat-card>
</div>
