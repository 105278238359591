<app-subheading
  [prompt]="
    'Devicetype - ' +
    (crudAction == Crud.Create
      ? 'Create'
      : crudAction == Crud.Update
      ? 'Update'
      : 'Delete')
  "
  [iconActions]="[
    {
      icon: 'arrow_back',
      toolTip: 'Return to User Groups',
      routerLink: '/devicetypes'
    }
  ]"
  matIcon="select_all"
></app-subheading>
<div class="page-container">
  <form class="standard-form" [formGroup]="devicetypeForm">
    <fieldset [disabled]="crudAction == Crud.Delete">
      <mat-form-field class="full-width">
        <input
          matInput
          formControlName="name"
          (change)="onFieldUpdate('name')"
          placeholder="Name"
          required
        />
        <mat-error *ngIf="devicetypeForm.controls['name'].hasError('required')">
          Name is required.
        </mat-error>
        <mat-error
          *ngIf="devicetypeForm.controls['name'].hasError('minlength')"
        >
          Name must be at least 3 characters long.
        </mat-error>
        <mat-error
          *ngIf="devicetypeForm.controls['name'].hasError('maxlength')"
        >
          Name must be no more than 35 characters long.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <textarea
          matInput
          formControlName="description"
          (change)="onFieldUpdate('description')"
          matAutosizeMinRows="3"
          matAutosizeMaxRows="10"
          matTextareaAutosize
          placeholder="Description"
          required
        ></textarea>
        <mat-error
          *ngIf="devicetypeForm.controls['description'].hasError('required')"
        >
          Description is required.
        </mat-error>
        <mat-error
          *ngIf="devicetypeForm.controls['description'].hasError('minlength')"
        >
          Description must be at least 10 characters long.
        </mat-error>
        <mat-error
          *ngIf="devicetypeForm.controls['description'].hasError('maxlength')"
        >
          Description must be no more than 500 characters long.
        </mat-error>
      </mat-form-field>
    </fieldset>

    <app-sensorslist
      [devicetype]="devicetype"
      *ngIf="crudAction != Crud.Create"
      [disabled]="crudAction == Crud.Delete"
    ></app-sensorslist>
    <br />
    <button
      mat-raised-button
      color="primary"
      *ngIf="crudAction == Crud.Create"
      [disabled]="!devicetypeForm.valid"
      (click)="onCreate()"
      id="btnCreate"
    >
      <mat-icon>add</mat-icon>
      Create
    </button>
    <button
      mat-raised-button
      color="primary"
      *ngIf="crudAction == Crud.Delete"
      (click)="onDelete()"
      id="btnDelete"
    >
      <mat-icon>clear</mat-icon>
      Delete
    </button>
  </form>
</div>
