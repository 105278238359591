<mat-sidenav-container>
  <!-- add this to the mat-sidebar tag to have menu auto close (click)="sidenav.toggle()" -->
  <mat-sidenav #sidenav (click)="sidenav.toggle()" class="no-print">
    <mat-nav-list>
      <a mat-list-item routerLink="/">
        <mat-icon>home</mat-icon>
        <span id="mainMenuHome">&nbsp;Home</span>
      </a>
      <a mat-list-item routerLink="about">
        <mat-icon>question_answer</mat-icon>
        <span id="mainMenuAbout">&nbsp;About</span>
      </a>

      <!-- Logged in and activated options -->
      <span
        *ngIf="(auth.loggedIn$ | async) && (auth.user$ | async)?.isActivated"
      >
        <a mat-list-item routerLink="myviews">
          <mat-icon>table_chart</mat-icon>
          <span id="mainMyviews">&nbsp;My Views</span>
        </a>

        <!-- User functions , if we have too many then add a navigation page  -->
        <a
          mat-list-item
          [routerLink]="['myprofile/', (auth.user$ | async)?.uid || '']"
        >
          <mat-icon>person</mat-icon>
          <span id="mainMenuMyProfile">&nbsp;My Profile</span>
        </a>

        <!-- User Management -->
        <a
          mat-list-item
          *ngIf="(auth.user$ | async)?.isAdmin"
          mat-list-item
          routerLink="users"
        >
          <mat-icon>how_to_reg</mat-icon>
          <span id="mainMenuUsers">&nbsp;Users</span>
        </a>
        <!-- Devicetypes -->
        <a
          mat-list-item
          *ngIf="(auth.user$ | async)?.isAdmin"
          mat-list-item
          routerLink="devicetypes"
        >
          <mat-icon>select_all</mat-icon>
          <span id="mainMenuDevicetypes">&nbsp;Device Types</span>
        </a>
        <!-- Devices -->
        <a
          mat-list-item
          *ngIf="(auth.user$ | async)?.isAdmin"
          mat-list-item
          routerLink="devices"
        >
          <mat-icon>memory</mat-icon>
          <span id="mainMenuDevices">&nbsp;Devices</span>
        </a>
        <!-- Applications -->
        <a
          mat-list-item
          *ngIf="(auth.user$ | async)?.isAdmin"
          mat-list-item
          routerLink="applications"
        >
          <mat-icon>category</mat-icon>
          <span id="mainMenuApplications">&nbsp;Applications</span>
        </a>
      </span>
      <!-- Logged in options -->
      <span *ngIf="auth.loggedIn$ | async">
        <mat-divider></mat-divider>
        <a
          mat-list-item
          routerLink="logout"
          (click)="logout()"
          *ngIf="auth.loggedIn$ | async"
        >
          <mat-icon>exit_to_app</mat-icon>
          <span id="mainMenuLogout">&nbsp;Logout</span>
        </a>
      </span>

      <!-- Not logged in -->
      <span *ngIf="!(auth.loggedIn$ | async)">
        <mat-divider></mat-divider>
        <a mat-list-item routerLink="login">
          <mat-icon>account_circle</mat-icon>
          <span id="mainMenuLogin">&nbsp;Login</span>
        </a>
      </span>
    </mat-nav-list>
  </mat-sidenav>

  <mat-toolbar color="primary" class="no-print">
    <button
      mat-icon-button
      class="noOutline"
      (click)="sidenav.open('mouse')"
      id="mainMenu"
    >
      <mat-icon>menu</mat-icon>
    </button>

    <div class="filler"></div>
    <div *ngIf="!isConnected" style="margin-right: 20px">
      <mat-chip-list>
        <mat-chip
          class="offline-indicator"
          title="Application is offline and will have more limited functionality."
        >
          Offline
        </mat-chip>
      </mat-chip-list>
    </div>

    &nbsp;
    <ng-container *ngIf="auth.user$ | async as user">
      <img
        *ngIf="user.isActivated"
        class="user-avatar"
        [src]="user.photoURL"
        [matTooltip]="user.displayName"
      />
      <img
        *ngIf="!user.isActivated"
        class="user-avatar"
        src="../../assets/images/Block_user_pic.png"
        matTooltip="You are not activated, contact an administrator to have your account activated to use this system."
      />
    </ng-container>
  </mat-toolbar>

  <div class="alert" *ngIf="showAlert">
    <button
      mat-raised-button
      style="float: right; margin-bottom: 10px"
      (click)="hideAlert()"
    >
      <span>Close</span>
    </button>
    <strong>{{ msgTitle }}</strong
    ><br />{{ msgBody }}
  </div>

  <router-outlet></router-outlet>
</mat-sidenav-container>
