<app-subheading
  [prompt]="
    (application$ | async)?.name +
    ': Trigger - ' +
    (crudAction == Crud.Create
      ? 'Create'
      : crudAction == Crud.Update
      ? 'Update'
      : 'Delete')
  "
  [iconActions]="[
    {
      icon: 'arrow_back',
      toolTip: 'Return to Application',
      routerLink: '/application/' + aid
    }
  ]"
  matIcon="table_chart"
></app-subheading>

<div class="page-container">
  <form class="standard-form" [formGroup]="viewForm">
    <fieldset [disabled]="crudAction == Crud.Delete">
      <mat-form-field class="full-width">
        <input
          matInput
          formControlName="name"
          (change)="onFieldUpdate('name')"
          placeholder="Name"
          required
        />
        <mat-error *ngIf="viewForm.controls['name'].hasError('required')">
          Name is required.
        </mat-error>
        <mat-error *ngIf="viewForm.controls['name'].hasError('minlength')">
          Name must be at least 3 characters long.
        </mat-error>
        <mat-error *ngIf="viewForm.controls['name'].hasError('maxlength')">
          Name must be no more than 35 characters long.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <textarea
          matInput
          formControlName="description"
          (change)="onFieldUpdate('description')"
          matAutosizeMinRows="3"
          matAutosizeMaxRows="10"
          matTextareaAutosize
          placeholder="Description"
          required
        ></textarea>
        <mat-error
          *ngIf="viewForm.controls['description'].hasError('required')"
        >
          Description is required.
        </mat-error>
        <mat-error
          *ngIf="viewForm.controls['description'].hasError('minlength')"
        >
          Description must be at least 10 characters long.
        </mat-error>
        <mat-error
          *ngIf="viewForm.controls['description'].hasError('maxlength')"
        >
          Description must be no more than 500 characters long.
        </mat-error>
      </mat-form-field>

      <div class="grid50percent">
        <div>
          <mat-form-field class="full-width">
            <mat-label>Default View Type *</mat-label>
            <mat-select
              formControlName="viewType"
              (selectionChange)="onFieldUpdate('viewType')"
            >
              <mat-option
                *ngFor="let viewTypeInfoItem of ViewTypeInfo"
                [value]="viewTypeInfoItem.viewType"
              >
                {{ viewTypeInfoItem.name }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="viewForm.controls['viewType'].hasError('required')"
            >
              View Type is required.
            </mat-error>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="full-width">
            <mat-label>Sensor *</mat-label>
            <mat-select
              formControlName="sensorRef"
              (selectionChange)="onFieldUpdate('sensorRef')"
              [compareWith]="objectComparisonFunction"
            >
              <mat-option
                *ngFor="let sensor of sensors"
                [value]="getSensorRef(sensor.id)"
              >
                {{ sensor.name }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="viewForm.controls['sensorRef'].hasError('required')"
            >
              Sensor is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </fieldset>

    <br />
    <button
      mat-raised-button
      color="primary"
      *ngIf="crudAction == Crud.Create"
      [disabled]="!viewForm.valid"
      (click)="onCreate()"
      id="btnCreate"
    >
      <mat-icon>add</mat-icon>
      Create
    </button>
    <button
      mat-raised-button
      color="primary"
      *ngIf="crudAction == Crud.Delete"
      (click)="onDelete()"
      id="btnDelete"
    >
      <mat-icon>clear</mat-icon>
      Delete
    </button>
  </form>
</div>
